<template>
  <v-card class="p-0 pb-1 mb-4" v-if="topico != null">
    <v-card class="m-0 p-0" flat :color="hexTints(area.cor,0.95)">
      <v-container fluid class="m-0 p-0">
        <v-row dense align="center" justify="start" class="h26 m-0 p-0" v-if="uc != null">
          <v-col cols="auto" class="m-0 p-0 ps-2 pe-1">
            <v-avatar dark :color="hexTints(area.cor,0.88)" size="20" class="m-0 p-0">
              <i v-i:duo#note#16 v-cHex="area.cor" class="btn-icon-left ps-1"></i>
            </v-avatar>
          </v-col>
          <v-col cols="auto" class="m-0 p-0">
            <div class="mt-0 line-height-3 f-lato fw-600 fs-8pt text-truncate">
              Tópico gerador
            </div>
          </v-col>
          <v-col cols="auto" class="m-0 p-0 ps-2">
            <span class="fs-8pt fw-800">ID:</span>
            <span class="ms-1 fs-8pt fw-600 f-lato">{{topico.id}}</span>
          </v-col>
          <v-col cols="" class="m-0 p-0 ps-2 text-right" v-if="noedit">
            <v-btn v-if="qtdeConteudos == 0" class="text-nonecase px-0 pe-1 me-2 fs-7pt" :color="area.cor" outlined x-small @click="$emit('viewConteudos',topico)">
              <i v-i:duo#plus-circle#14 v-cHex="area.cor" class="btn-icon-left ps-1"></i>
              <span class="fs-8pt fw-500 me-1">Adicionar Conteúdos</span>
            </v-btn>
            <v-btn v-if="qtdeConteudos > 0" class="text-nonecase px-0 pe-1 me-2 fs-7pt" :color="area.cor" outlined x-small @click="$emit('viewConteudos',topico)">
              <i v-i:duo#note#14 v-cHex="area.cor" class="btn-icon-left ps-1"></i>
              <span class="fs-8pt fw-500 me-1">{{qtdeConteudos}}</span>
              conteúdos
            </v-btn>
          </v-col>
          <v-col cols="auto" class="m-0 p-0 text-right" v-if="noedit">
            <v-btn class="me-1" color="primary" x-small icon @click="$emit('editar',topico)">
              <i v-i:duo#pencil#16 v-cHex="area.cor" class="btn-icon-left ps-1"></i>
            </v-btn>
            <v-btn class="me-1" color="primary" x-small icon @click="$emit('excluir',topico)">
              <i v-i:duo#trash#16 v-cHex="area.cor" class="btn-icon-left ps-1"></i>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card flat class="m-0 p-2">
      <v-container class="m-0 p-0" :style="'background-color:'+hexTints(area.cor,0.96)">
        <v-row class="m-0 p-0" align="center" justify="start">
          <v-col cols="auto" class="m-0 p-0" v-if="noedit">
            <div class="">
              <v-btn v-if="!up" class="m-0 p-0" color="primary" x-small icon @click="$emit('moveUp',topico)">
                <i v-i:duo#arrow-fat-lines-up#16 v-cHex="area.cor" class="btn-icon-left ps-1"></i>
              </v-btn>
              <i v-if="up" v-i:duo#arrow-fat-lines-up#16 v-cHex="cor.active.S" class="btn-icon-left ps-1"></i>
            </div>
            <div class="">
              <v-btn v-if="!down" class="m-0 p-0" color="primary" x-small icon @click="$emit('moveDown',topico)">
                <i v-i:duo#arrow-fat-lines-down#16 v-cHex="area.cor" class="btn-icon-left ps-1"></i>
              </v-btn>
              <i v-if="down" v-i:duo#arrow-fat-lines-down#16 v-cHex="cor.active.S" class="btn-icon-left ps-1"></i>
            </div>
          </v-col>
          <v-col cols="" class="m-0 p-0">
            <v-card class="m-0 p-1 px-1 f-raleway fs-10pt line-height-5" flat :color="hexTints(area.cor,0.98)">
              {{topico.texto}}
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-row dense class="mt-1" v-if="!noedit">
        <v-col cols="12" class="m-0 p-0">
          <feedcontroler ref="fcontroler" :area="area" :uc="uc" :color="area.cor" :rdbPath="topicoPath" :key="refresh"/>
        </v-col>
      </v-row>
    </v-card>
  </v-card>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import feedcontroler from '@/views/feedsComponent/feedControler.vue'

export default {
  name: "topicoitem",
  components: { feedcontroler },
  props: {
    noedit: { default: false, type: Boolean },
    area: { default: null, type: Object },
    uc: { default: null, type: Object },
    topico: { default: null, type: Object },
    down: { default: false, type: Boolean },
    up: { default: false, type: Boolean },
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      topicoPath: null,
      qtdeConteudos: 0,
      conteudos: {},
    }
  },
  watch: {
    topico() {
      this.build();
    }
  },
  mounted: function() {
    var self = this;

    //console.log("this.uc",this.uc);
    if(this.topico != null) {
      this.topicoPath = "/curriculo/socialData/topicos/"+this.topico.id;
      self.build();
    }
  },
  methods:{

    build() {
      var self = this;
      if(this.topico == null) return;
      rdb.ref('/curriculo/topicos/topicos/'+this.topico.id+'/conteudos').on('value',function(snapshot) {
        self.conteudos = {};
        self.qtdeConteudos = 0;
        var elem = snapshot.val();
        if(elem != null) {
          self.conteudos = elem;
          self.qtdeConteudos = Object.keys(self.conteudos).length;
          console.log("self.conteudos",self.conteudos);
        }
        self.refresh++;
      });
    }
  }
}
</script>

<style scoped>
</style>
